<template>
  <div>
    <v-layout v-if="priceStatus" wrap py-4>
      <v-flex text-left xs12 lg12  style="background-color: whitesmoke !important">
        <span class="priceLabel">
          <v-layout wrap pa-4>
            <v-flex xs6> Original Price : </v-flex>
            <v-flex xs6>
              {{ Number(productcost)  }}
            </v-flex>
            <v-flex xs6> Offer Percentage : </v-flex>
            <v-flex xs6>
              {{ offer }}
            </v-flex>
            <v-flex xs6> Offer Amount : </v-flex>
            <v-flex xs6>
              {{ price.savedAmount }}
            </v-flex>
            <!-- <v-flex xs6> Delivery charge : </v-flex>
            <v-flex xs6> {{ price.deliveryCharge }} </v-flex> -->
            <v-flex xs6> GST : </v-flex><v-flex xs6> {{ price.gst }} </v-flex>
            <v-flex xs6>
              <span class="priceLabelBold" style="font-weight: bold">
                Amount from customer :
              </span>
            </v-flex>
            <v-flex xs6>
              <span class="priceLabelBold" style="font-weight: bold">
                INR {{ totalCost }}
              </span>
            </v-flex>
            <v-flex xs12 lg12 xl8>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs6> Styloop Commision :</v-flex>
            <v-flex xs6> {{ price.styloopCommision }} </v-flex>
            <v-flex xs6> Styloop Gst:</v-flex>
            <v-flex xs6> {{ price.styloopGST }} </v-flex>
            <v-flex xs6> Tcs :</v-flex>
            <v-flex xs6> {{ price.tcs }} </v-flex>
            <v-flex xs6> Payment Gateway Charge : </v-flex>
            <v-flex xs6> {{ price.paymentGatewayCharge }} </v-flex>

            <v-flex xs6>
              <span class="priceLabelBold" style="font-weight: bold">
                Amount To Seller :
              </span>
            </v-flex>
            <v-flex xs6>
              <span class="priceLabelBold" style="font-weight: bold">
                INR {{ price.amountToSeller }}
              </span>
            </v-flex>
            <v-flex xs8 py-1>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs6>
              <span class="priceLabelBold" style="font-weight: bold">
                Amount To Seller after GST:
              </span>
            </v-flex>
            <v-flex xs6>
              <span class="priceLabelBold" style="font-weight: bold">
                INR {{ price.amountToSeller - price.gst }}
              </span>
            </v-flex>
          </v-layout>
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["productcost", "offer" ,"subcategoryData" ],
  data() {
    return {
      priceStatus: true,
      price: {
        productCost: null,
        deliveryCharge: null,
        gst: null,
        finalCustomerCharge: null,
        styloopCommision: null,
        styloopGST: null,
        tcs: null,
        paymentGateewayCharge: null,
        amountToSeller: null,
        gstPercentage: null,
        styloopCommisionPercentage: null,
        styloopGstPercentage: 18,
        tcsPercentage: null,
        paymentGatewayChargePercentage: null,
        offerAmount: null,
        savedAmount: null,
      },
      totalCost: null,
    };
  },
  beforeMount() {
    this.getPriceData();
  },
   computed: {
    userData() {
      return this.$store.state.userData;
    },
    subcategoryInfo() {
      if (this.subcategoryData) return this.subcategoryData;
      else return {};
    },
  },
  watch: {
    productcost() {
      this.amtAdd(this.subcategoryInfo.gstPercentage);
    },
     subcategoryInfo: {
      handler: function () {
        this.amtAdd(this.subcategoryInfo.gstPercentage);
      },
      deep: true,
    },
    // profitmargin() {
    //   this.amtAdd();
    // },
  },
  methods: {
    getPriceData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/settings/getlistobject",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
         if (response.data.status) {
            var priceDetails = response.data.data;
            this.price.tcsPercentage = priceDetails.tcspercentage;
            this.price.paymentGatewayChargePercentage =
              priceDetails.paymentgatewaychargepercentage;

            this.amtAdd(this.subcategoryInfo.gstPercentage);
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    amtAdd(gstPercentage) {
      this.priceStatus = true;
    if (!gstPercentage) gstPercentage = 0;
      this.priceStatus = true;
      this.totalCost = Number(this.productcost);
      this.price.gstPercentage = gstPercentage;
      this.price.styloopGstPercentage = gstPercentage;
      this.price.productCost = this.totalCost;
      this.price.styloopCommision =
        Math.round(
          (this.price.styloopCommisionPercentage / 100) * this.totalCost * 100
        ) / 100;
      this.price.styloopGST =
        Math.round(
          (this.price.styloopGstPercentage / 100) *
            this.price.styloopCommision *
            100
        ) / 100;
      //here offer
      var offerpercentage = Number(this.offer);
      if (offerpercentage > 0) {
        this.price.offerAmount = (offerpercentage / 100) * this.totalCost;
        this.price.offerAmount = Math.round(this.price.offerAmount * 100) / 100;
        this.price.savedAmount = this.price.offerAmount;
        this.totalCost = this.totalCost - this.price.offerAmount;
      }

      this.price.tcs =
        Math.round((this.price.tcsPercentage / 100) * this.totalCost * 100) /
        100;
      this.price.paymentGatewayCharge =
        Math.round(
          (this.price.paymentGatewayChargePercentage / 100) *
            this.totalCost *
            100
        ) / 100;
      this.price.gst = (this.price.gstPercentage / 100) * this.totalCost;
      this.price.gst = Math.round(this.price.gst * 100) / 100;
      this.totalCost = this.price.gst + this.totalCost;
      this.totalCost = Math.round(this.totalCost * 100) / 100;

      this.price.amountToSeller = Math.round(
        this.totalCost -
          this.price.styloopCommision -
          this.price.styloopGST -
          this.price.tcs -
          this.price.paymentGatewayCharge
          //  -
          // this.price.deliveryCharge
      );
      if ((this.totalCost > this.subcategoryInfo.gstLevel2MinumumPrice)&&(this.subcategoryInfo.gstLevel2MinumumPrice>0))
      {
        if(this.price.gstPercentage!=this.subcategoryInfo.gstLevel2Percentage)
       {
          this.amtAdd(this.subcategoryInfo.gstLevel2Percentage);
        this.$emit("stepper", {
          price: this.totalCost,
        });
       }
      }
      else {
        this.$emit("stepper", {
          price: this.totalCost,
        });
      }
    },
  },
};
</script>
<style scoped>
.priceLabel {
  color: #30B868;
  font-family: opensansregular;
  letter-spacing: 0.54px;
   font-size: 14px;

}
.riceLabelBold {
  font-family: opensansbold;
}
</style>